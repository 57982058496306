'use strict';

/* exported
 localesCtrl
  */

function localesCtrl($scope, locales, appConfig) {
  $scope.locales = locales;
  $scope.appConfig = appConfig;

}

/**
 * Created by john on 2/8/17.
 */
'use strict';

/* exported
 localesViewCtrl
 */

function localesViewCtrl($scope, city, appConfig) {
  $scope.city = city;

  $scope.getCityCoverStyle = function() {
    if (city.pictures.length) {
      var coverUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + city.pictures[0].url + '?height=300';
      return {'background-image': 'url("' + coverUrl + '")'};
    }
  };

  $scope.getThumbnailStyle = function(image) {
    var thumbnailUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + image.url + '?width=600';

    return {'background-image': 'url("' + thumbnailUrl + '")'};
  };
}

/**
 * Created by john on 3/8/17.
 */
'use strict';

/* exported
 localeEditCtrl
 */

function localeEditCtrl($scope, locale, Restangular, $state) {
  $scope.locale = locale;

  $scope.save = function() {

    if ($scope.localeForm.$invalid) {
      $scope.localeForm.name.$setTouched();
      $scope.localeForm.locale.$setTouched();
      $scope.localeForm.flagUrl.$setTouched();

      return;
    }

    var promise = $scope.locale._id ? $scope.locale.put() : Restangular.all('locales').post($scope.locale);

    promise
      .then(function(locale) {
        $scope.$emit('notify', {type: 'success', title: 'Create locale', message: 'locale created with Success'});
        $scope.locale = locale;
        $state.go('root.editLocale', {localeId: locale._id});
      })
      .catch(function(err) {
        $scope.$emit('notify', {type: 'error', title: 'Create locale', message: 'Failed to create locale'});

        $scope.globalError = err.data.message;
      });

  };
}
